import awsConfig from '../config/awsConfig';
import {GetObjectCommand, ListObjectsV2Command, S3Client, PutObjectCommand, DeleteObjectsCommand} from "@aws-sdk/client-s3";
import {fromCognitoIdentityPool} from "@aws-sdk/credential-provider-cognito-identity";
import {getSignedUrl} from "@aws-sdk/s3-request-presigner";
import {getIdentityId} from "./cognitoService";
import { getSessionToken } from './cognitoService';

const createS3Client = (token) => {
  return new S3Client({
    region: awsConfig.region,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: awsConfig.region },
      identityPoolId: awsConfig.identityPoolId,
      logins: {
        [`cognito-idp.${awsConfig.region}.amazonaws.com/${awsConfig.userPoolId}`]: token,
      },
    }),
  });
};

const getS3Items = async (folderPath) => {
  const token = await getSessionToken();
  const s3Client = await createS3Client(token);
  const identityId = await getIdentityId();
  const userPath = `userData/${identityId}`;

  const command = new ListObjectsV2Command({
    Bucket: awsConfig.bucketName,
    Prefix: `${userPath}/${folderPath}`,
  });

  try {
    const data = await s3Client.send(command);
    return data.Contents;
  } catch (error) {
    console.error('Error fetching S3 items:', error);
    throw new Error(`Error fetching S3 items: ${error}`);
  }
};


const createS3Items = async (folderName, files) => {
  const token = await getSessionToken();
  const s3Client = await createS3Client(token);
  const identityId = await getIdentityId();
  const userPath = `userData/${identityId}`;
  const promises = files.map(file => {
    console.log("filename", file.name)
    const key = `${userPath}/${folderName}/${file.name}`;
    const uploadParams = {
      Bucket: awsConfig.bucketName,
      Key: key,
      Body: file, // assuming file.content holds the file data
    };
    return s3Client.send(new PutObjectCommand(uploadParams));
  });

  try {
    await Promise.all(promises);
    console.log("All files uploaded successfully");
  } catch (error) {
    console.error('Error uploading files:', error);
    throw new Error(`Error uploading files: ${error}`);
  }
};


const deleteS3Items = async (folderPath) => {
  const token = await getSessionToken();
  const s3Client = await createS3Client(token);
  const identityId = await getIdentityId();
  const userPath = `userData/${identityId}`;
  // First, list all objects in the folder
  const command = new ListObjectsV2Command({
    Bucket: awsConfig.bucketName,
    Prefix: `${userPath}/${folderPath}`,
  });
  const listedObjects = await s3Client.send(command);

  console.log("listedObjects", listedObjects)
  if (listedObjects.Contents.length === 0) return;

  // Then, prepare and send a delete request for all listed objects
  const deleteParams = {
    Bucket: awsConfig.bucketName,
    Delete: {
      Objects: listedObjects.Contents.map(file => ({ Key: file.Key }))
    }
  };

  try {
    await s3Client.send(new DeleteObjectsCommand(deleteParams));
    console.log("All items deleted successfully");
  } catch (error) {
    console.error('Error deleting items:', error);
    throw new Error(`Error deleting items: ${error}`);
  }
};


const generatePresignedUrl = async (key) => {
  const token = await getSessionToken();
  const command = new GetObjectCommand({
    Bucket: awsConfig.bucketName,
    Key: key,
  });

  try {
    const s3Client = await createS3Client(token);
    const url = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
    return url;
  } catch (error) {
    throw new Error(`Error generating signed URL: ${error}`);
  }
};

export { getS3Items, createS3Items, deleteS3Items, generatePresignedUrl, createS3Client};
