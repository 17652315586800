// Extract the first occurrence of a number in the filename
export function extractNumber(filename) {
    const matches = filename.match(/\d+/g);
    if (matches && matches.length > 0) {
      return parseInt(matches[matches.length - 1], 10);
    }
    return null;
  }
  
  
  // Pad the number with leading zeros
  export function padNumber(number, width) {
    return String(number).padStart(width, '0');
  }
  
  // Extract the base name from the filename (everything before the number)
  export function extractBaseName(filename) {
    const extensionIndex = filename.lastIndexOf('.');
    const nameWithoutExtension = filename.substring(0, extensionIndex);
  
    const matches = nameWithoutExtension.match(/\d+/g);
    if (matches && matches.length > 0) {
      const lastNumber = matches[matches.length - 1];
      const lastNumberIndex = nameWithoutExtension.lastIndexOf(lastNumber);
      return nameWithoutExtension.substring(0, lastNumberIndex);
    } else {
      // If no number is found, return the entire name without extension
      return nameWithoutExtension;
    }
  }
  